DG.inView = function() {
  var $el;

  function init() {
    $el = $('[data-in-view-action]');

    if($el.length > 0){
      scrollInit();
    } 
  }

  function scrollInit(){
    $el.each(elementAction);
  }

  function elementAction(){
    var $el = $(this);
    var offset = ($el.filter('[data-offset]').length > 0) ? $el.data('offset') : '95%';

    $el.waypoint(function(direction) {
      if ($el[0].hasAttribute('data-toggle')) {
        $el.toggleClass('in-view');
        $el.trigger('in-view');
      }else{
        $el.addClass('in-view');
        $el.trigger('in-view');

        if ($el[0].hasAttribute('data-animated-icon') && !$el.hasClass('animated-icon--active')) {
          DG.animatedIcon.inViewPlay($el);
        }
      }


    }, {
      offset: offset
    })
  }

  return {
    init: init
  }

}();