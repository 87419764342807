DG.faqs = function() {
  'use strict';

  function init() {
    $('[data-faq-trigger]').on('click', toggleFaq);
  }

  function toggleFaq() {
    var $this = $(this);
    
    $('[data-faq-target]').not($this).slideUp();
    $('[data-faq-trigger]').not($this).removeClass('faqs__question--open');

    if ($this.hasClass('faqs__question--open')) {
      $this.removeClass('faqs__question--open').next('[data-faq-target]').slideUp();
    } else {
      $this.addClass('faqs__question--open').next('[data-faq-target]').slideDown();
    }
  }

  return {
    init: init
  };

}();