DG.cookieBanner = function() {
	var $cookieBanner;

	function init() {
		if (!readCookie('acceptCookie') >= 1) {
			initdisclaimer();
		}
	}

	function initdisclaimer() {
		$cookieBanner = bh('cookie-banner');
		var $confirmTrigger = $cookieBanner.find('[data-element="confirm"]');

		$('html').addClass('overflow cookie-banner-open')
		$cookieBanner.addClass('cookie-banner--active');
		$confirmTrigger.on('click', accept);
	}

	function accept() {
		$cookieBanner.addClass('cookie-banner--hide');

		setTimeout(function(){
			$('html').removeClass('overflow cookie-banner-open')
			$cookieBanner.removeClass('cookie-banner--hide cookie-banner--active');
		}, 600); // play animation

		createCookie('acceptCookie', 1, 2147483647);

		location.reload()
	};

	// set cookie
	function createCookie(name, value, days) {
		var expires;

		if (days) {
			const date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			expires ='; expires='+date.toGMTString();
		}
		else {
			expires = '';
		}
		document.cookie = name+'='+value+expires+'; path=/';
	};

	// read cookie
	function readCookie(name) {
		var nameEQ = name + '=';
		var ca = document.cookie.split(';');

		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1,c.length);
			}

			if (c.indexOf(nameEQ) === 0) {
				return c.substring(nameEQ.length,c.length);
			}
		}
		return null;
	};

	return {
		init: init
	}

}();