// --------------------------------------------------------------------------
// application.js
// --------------------------------------------------------------------------
// This file imports and initialises modules.
//
// Add module names to the modules array for automatic initialisation.
//
// No specific javascript should be placed in this file.
// --------------------------------------------------------------------------
$(function() {

  'use strict';

  var rellax = new Rellax('[data-parallax]');

  // var modules = [ 'inView', 'anchors', 'cookieBanner'];

  var modules = [ 'inView', 'faqs', 'cookieBanner', 'mailchimpForm'];

  for(var i = 0; i < modules.length; i++) {
    DG[modules[i]].init();
  }

});