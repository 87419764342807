DG.mailchimpForm = function() {
  'use strict';

  function init() {
    var $form = bh('mailchimp-form');

    if ($form.length) {
      $form.each(initForm);

      mc4wp.forms.on('subscribed', function(form, data) {
        if ($('[data-redirect-url]').length) {
          window.location = $('[data-redirect-url]').data('redirect-url');
        }
      });
    }
  }

  function initForm() {
    $(this).find('[name="your-first-name"]').attr('data-parsley-maxwords', '1')
    $(this).find('.mailchimp-form__source').attr('value', $(this).data('mailchimp-source'));
    $(this).find('.wpcf7-validates-as-required').attr('data-parsley-required', true)

    var mailchimpGroup = $('[data-mailchimp-group]').attr('data-mailchimp-group')
    $( "span:contains('" + mailchimpGroup + "')" ).prev().prop('checked', 'checked')

    initParsley($(this).find('.wpcf7-form, .mc4wp-form'))
  }

  function initParsley(form) {
    form.parsley({
      errorsMessagesDisabled: true,
      classHandler: function (el) {
        return el.$element.closest('.mailchimp-form__control');
      }
    }).on('form:submit', function (e) {
      
    });
  }

  return {
    init: init
  };

}();